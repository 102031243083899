const Aside = () => {
    return (
        <div id="aside">
            <div className="vertitcal-line"></div>
            <ul>
                <li>
                    <a title="LinkedIn" href="https://www.linkedin.com/in/oliver-fox-uk/" target="_blank" rel="noreferrer">
                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 74 72.792"><path d="M16.564,72.8H1.222V24.2H16.564ZM8.885,17.573A8.885,8.885,0,0,1,0,8.75,8.813,8.813,0,0,1,8.885.01,8.813,8.813,0,0,1,17.77,8.75,8.886,8.886,0,0,1,8.885,17.573ZM73.983,72.8H58.675V49.144c0-5.638-.116-12.869-7.976-12.869-7.976,0-9.2,6.126-9.2,12.463V72.8H26.174V24.2H40.888v6.629H41.1c2.048-3.818,7.051-7.848,14.516-7.848C71.146,22.984,74,33.042,74,46.106V72.8Z" transform="translate(0 -0.01)"/></svg>
                    </a>
                </li>
                <li>
                    <a title="GitHub" href="https://github.com/digel4/" target="_blank" rel="noreferrer">
                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 94.347 90.601"><path d="M31.557,80.951c0,.375-.437.674-.989.674-.628.056-1.065-.244-1.065-.674,0-.375.437-.674.989-.674C31.062,80.221,31.557,80.521,31.557,80.951Zm-5.916-.843c-.133.375.247.806.818.918a.9.9,0,0,0,1.179-.375c.114-.375-.247-.806-.818-.974A.983.983,0,0,0,25.641,80.108Zm8.408-.318c-.552.131-.932.487-.875.918.057.375.552.618,1.122.487.552-.131.932-.487.875-.862C35.114,79.977,34.6,79.734,34.049,79.79ZM46.565,8C20.182,8,0,27.727,0,53.712A47.026,47.026,0,0,0,32.242,98.524c2.435.431,3.291-1.049,3.291-2.267,0-1.162-.057-7.569-.057-11.5,0,0-13.315,2.81-16.111-5.583,0,0-2.168-5.452-5.288-6.857,0,0-4.356-2.941.3-2.885a10.069,10.069,0,0,1,7.342,4.833c4.166,7.231,11.147,5.152,13.867,3.915a10.354,10.354,0,0,1,3.043-6.313C28,70.7,17.272,69.186,17.272,51.164c0-5.152,1.446-7.737,4.489-11.035a17.452,17.452,0,0,1,.495-12.721c3.976-1.218,13.125,5.058,13.125,5.058a45.6,45.6,0,0,1,23.891,0s9.149-6.295,13.125-5.058a17.444,17.444,0,0,1,.495,12.721c3.043,3.316,4.908,5.9,4.908,11.035,0,18.079-11.2,19.521-21.837,20.7,1.75,1.48,3.234,4.29,3.234,8.693,0,6.313-.057,14.126-.057,15.662,0,1.218.875,2.7,3.291,2.267,19.022-6.22,31.918-24,31.918-44.775C94.347,27.727,72.948,8,46.565,8ZM18.489,72.615c-.247.187-.19.618.133.974.3.3.742.431.989.187.247-.187.19-.618-.133-.974C19.174,72.5,18.736,72.371,18.489,72.615ZM16.435,71.1c-.133.244.057.543.437.731a.571.571,0,0,0,.818-.131c.133-.244-.057-.543-.437-.731C16.872,70.854,16.568,70.91,16.435,71.1ZM22.6,77.767c-.3.244-.19.806.247,1.162.437.431.989.487,1.236.187.247-.244.133-.806-.247-1.162C23.416,77.523,22.845,77.467,22.6,77.767Zm-2.168-2.754c-.3.187-.3.674,0,1.105s.818.618,1.065.431a.845.845,0,0,0,0-1.162C21.228,74.956,20.734,74.769,20.429,75.013Z" transform="translate(0 -8)"/></svg>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default Aside;